import process from "process";
import devConstants from "./constants.development";
import testConstants from "./constants.test";
import prdConstants from "./constants.production";
import genStorageKeyByAppType from "../utils/app/genStorageKeyByAppType";

const envConstantsMap = {
  production: prdConstants,
  test: testConstants,
  development: devConstants,
};

// @ts-ignore
const envConstants = envConstantsMap[process.env.DEPLOY_ENV] || devConstants;

export const {
  SERVER_URL,
  DEPLOY_DIR,
  APP_URL,
  H5_STATIC_SERVER_URL,
  WX_APPID,
  WX_MP_APP_ID,
  SHOW_MAI_DIAN_LOG,
  ALLOW_MOBILE_LOGIN_PAGE,
  JW_APP_URL,
  JW_KUAIPK_APP_URL,
  JW_KUAIJK_APP_URL,
  JW_KUAIXK_APP_URL,
  JW_KUAICJ_APP_URL,
} = envConstants;

// 后端服务前缀
export const BASE_SYSTEM_USER = "/base-system-user";
export const SUPPORT_SERVICE = "/ttfs-support";
export const BASE_SCHOOL_COURSE = "/base-school-course";
export const BASE_SCHOOL_TEACHER = "/base-school-teacher";
export const BASE_SCHOOL = "/base-school";
export const SCHEDULING_ENGINE = "/schedule/engine/scheduleproject";
export const BASE_PARSE = "/base-parse";
export const BASE_SALES = "/base-sales";
export const BASE_USER_BEHAVIOR = "/base-user-behavior";
export const BASE_NCEE_CLASS_SCHEDULING = "/base-nceeclassscheduling"; // 新版课表
export const BASE_ARRANGE_EXAM_ROOM = "/base-arrangexamroom"; // 排监考
export const BASE_SCHEDULING = "/base-newscheduling"; // 新版排课
export const BASE_NEW_SCHEDULING = "/base-newscheduling"; // 新版排课

export const KUAIJZ_MP_APP_ID = "wxb31a32b804116817"; // 倍思家长微信小程序appid

// 前端localStorage相关Key
export const USER_TOKEN = genStorageKeyByAppType('user_token'); // token
export const USER_ID = genStorageKeyByAppType('user_id'); // 用户id
export const WECOM_CORP_ID = genStorageKeyByAppType('wecom_corp_id'); // 企微用户企业id
export const SCHOOL_INFO = genStorageKeyByAppType('school_info'); // 学校信息
export const SCHEMA_SHARED = genStorageKeyByAppType('schema_shared'); // 是否已经分享
export const SCHEDULE_HAS_TOUR = genStorageKeyByAppType('schedule_has_tour'); // 是否已经引导
export const SCHEDULE_TIP_ODD = genStorageKeyByAppType('schedule_tip_odd'); // 是否已经提示单双周操作
export const SCHEDULE_TIP_COMBINE = genStorageKeyByAppType('schedule_tip_combine'); // 是否已经提示合班操作
export const SCHEDULE_TIP_STRATIFIED = genStorageKeyByAppType('schedule_tip_stratified'); // 是否已经提示分层操作
export const SCHEDULE_TIP_SORT = genStorageKeyByAppType('schedule_tip_sort'); // 是否已经提示排序操作
export const SCHEDULE_TIP_BATCH_INPUT = genStorageKeyByAppType('schedule_tip_batch_input'); // 是否已经提示批量操作
export const SCHEDULE_IMPORT_SHEETS_DATA = genStorageKeyByAppType('schedule_import_sheets_data'); // 导入文件后的表格数据
export const KUAIPK_TIPS = genStorageKeyByAppType('kuaipk_tips'); // 提示集合
export const SELECT_SUBJECT_CURRENT_TASK = genStorageKeyByAppType('select_subject_current_task'); // 提示集合
export const DIVIDE_CLASS_CURRENT_TASK = genStorageKeyByAppType('divide_class_current_task'); // 提示集合
export const CLASS_SCHEDULE_CURRENT_TASK = genStorageKeyByAppType('class_schedule_current_task'); // 提示集合
export const DIVIDE_INVIGILATE_CURRENT_TASK = genStorageKeyByAppType('divide_invigilate_current_task'); // 排监考当前taskId
export const SCHEDULE_CURRENT_TASK_ID = genStorageKeyByAppType('schedule_current_task_id'); // 当前方案id
export const NEW_SCHEDULE_CURRENT_TASK_ID = genStorageKeyByAppType('new_schedule_current_task_id'); // 走班排课当前任务ID
export const TRAINING_COURSE_CURRENT_SCHEDULE_ID = genStorageKeyByAppType('training_course_current_schedule_id'); // 培训班排课当前taskId
export const PREVIEW_EXPORT_SELECT_TEMPLATE_ID = genStorageKeyByAppType('preview_export_select_template_id'); // 排课预览导出选中模板ID
export const PREVIEW_EXPORT_PARAMS = genStorageKeyByAppType('preview_export_params'); // 排课预览导出参数缓存
export const COURSE_HOUR_STAT_WEEK_CONFIG = genStorageKeyByAppType('course_hour_stat_week_config'); // 课时统计周次配置
export const VIP_EXPIRED_MODAL = genStorageKeyByAppType('vip_expired_modal');

export const VISITOR_FROM = genStorageKeyByAppType('visitor_from'); // 用户来源
export const VISITOR_REF = genStorageKeyByAppType('visitor_ref'); // 用户页面来源

// 待清除
export const KUAIPK_CURRENT_TEAM = "KUAIPK_CURRENT_TEAM"; // 排课当前团队信息
