// 生产环境配置
import { isJwInlandApp, isJwIntlApp } from "../utils/app/env";

const deployDir = isJwInlandApp ? "/kuaipk/" : isJwIntlApp ? "/kuaipk/" : "/";
const appUrl = isJwInlandApp
  ? "https://jw.basejy.com/kuaipk"
  : isJwIntlApp
  ? "https://edu.basejy.com/kuaipk"
  : "https://kuaipk.basejy.com";
const JW_APP_URL = isJwInlandApp
  ? "https://jw.basejy.com"
  : isJwIntlApp
  ? "https://edu.basejy.com"
  : "https://jw.basejy.com";

export default {
  SERVER_URL: "https://api.basejy.com", // 后端服务域名
  DEPLOY_DIR: deployDir, // 项目部署的目录
  APP_URL: appUrl, // 本项目访问地址
  H5_STATIC_SERVER_URL: "https://h5.basejy.com", // h5页面访问地址
  WX_APPID: "wxae050b3b7db880a5", // 微信公众号appid
  WX_MP_APP_ID: "wxef1aa2457078e512", // 微信小程序appid
  SHOW_MAI_DIAN_LOG: false, // 是否打印埋点日志到浏览器控制台
  ALLOW_MOBILE_LOGIN_PAGE: false, //false, // 是否打开手机登录页面
  JW_APP_URL: JW_APP_URL,
  JW_KUAIPK_APP_URL: `${JW_APP_URL}/kuaipk`,
  JW_KUAIJK_APP_URL: `${JW_APP_URL}/kuaijk`,
  JW_KUAIXK_APP_URL: `${JW_APP_URL}/kuaixk`,
  JW_KUAICJ_APP_URL: `${JW_APP_URL}/kuaicj`,
};
