import { store, useAppSelector } from "@/store";

export const isFromWelink = () => {
  return store.getState().user?.userInfo?.source === 6;
};

const useIsFromWelink = () => {
  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.user.userInfo,
  }));

  return userInfo?.source === 6;
};

export default useIsFromWelink;
