// 测试环境配置
import { isJwInlandApp, isJwIntlApp } from "../utils/app/env";

const deployDir = isJwInlandApp
  ? "/jw-inland-kuaipk/"
  : isJwIntlApp
  ? "/jw-intl-kuaipk/"
  : "/kuaipk/";
const hostname = "http://v5.dev.basejy.com";
const appUrl = isJwInlandApp
  ? `${hostname}/jw-inland-kuaipk`
  : isJwIntlApp
  ? `${hostname}/jw-intl-kuaipk`
  : `${hostname}/kuaipk`;
const JW_APP_URL = isJwInlandApp
  ? `${hostname}/jw-inland`
  : isJwIntlApp
  ? `${hostname}/jw-intl`
  : `${hostname}/jw-inland`;

export default {
  SERVER_URL: "http://ttfs.154basejy.com", // 后端服务域名
  DEPLOY_DIR: deployDir, // 项目部署的目录
  APP_URL: appUrl, // 本项目访问地址
  H5_STATIC_SERVER_URL: "https://tencent.dev-env.basejy.com", // h5页面访问地址
  WX_APPID: "wx99bc4493f44c706f", // 微信公众号appid
  WX_MP_APP_ID: "wxef1aa2457078e512", // 微信小程序appid
  SHOW_MAI_DIAN_LOG: true, // 是否打印埋点日志到浏览器控制台
  ALLOW_MOBILE_LOGIN_PAGE: true, // 是否打开手机登录页面
  JW_APP_URL: JW_APP_URL,
  JW_KUAIPK_APP_URL: `${JW_APP_URL}-kuaipk`,
  JW_KUAIJK_APP_URL: `${JW_APP_URL}-kuaijk`,
  JW_KUAIXK_APP_URL: `${JW_APP_URL}-kuaixk`,
  JW_KUAICJ_APP_URL: `${JW_APP_URL}-kuaicj`,
};
